import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Seo } from 'components';

import * as Elements from 'elements';
import * as Components from 'components';

/**
 * Лендинг департамента
 */
export default ({ data }) => (
  <>
    <Seo
      title={data.page.frontmatter.full_title || data.page.frontmatter.title}
      og={data.page.frontmatter.og}
      description={data.page.frontmatter.description}
    />
    <MDXProvider components={{ ...Components, ...Elements }}>
      <MDXRenderer>{data.page.body}</MDXRenderer>
    </MDXProvider>
  </>
);

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: {eq: $id}) {
      frontmatter {
        title
        full_title
        redirect
        og
        description
      }
      body
    }
  }
`;
